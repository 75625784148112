import { convertNullIfEmpty as convNull } from "@/utils/null-check";
import { getFullAddress } from "@/utils/string";
import cloneDeep from "lodash/cloneDeep";

class ProjectModel {
    isDeveloper = false;
    isAgency = false;
    payload;

    constructor(payload, { isDeveloper, isAgency }) {
        this.isDeveloper = isDeveloper;
        this.isAgency = isAgency;
        this.payload = payload;
    }
    toCreatePayload() {
        let e = this.toPostPayload();
        e = {
            ...e,
            bankerIds: this.payload.bankerIds || [],
            lawyerIds: this.payload.lawyerIds || []
        };

        return e;
    }
    toEditPayload() {
        return this.toPostPayload();
    }
    toPostPayload() {
        let e = cloneDeep(this.payload);
        let request = {
            countryId: e.countryId || "",
            stateId: e.stateId || "",
            cityId: e.cityId || "",
            areaId: e.areaId || "",
            buildingId: e.buildingId || "",

            name: e.name || "",
            referenceCode: e.referenceCode || "",
            tenureType: convNull(e.tenureType) || [],
            furnishingType: e.furnishingType || "",
            propertyCategory: convNull(e.propertyCategory) || "",
            projectType: e.projectType || "",
            introduction: e.introduction || "",
            latitude: convNull(e.latitude) ? e.latitude.toString() : "",
            longitude: convNull(e.longitude) ? e.longitude.toString() : "",
            status: e.status || "",
            mainPhoto: convNull(e.mainPhoto) || [],
            gallery: convNull(e.gallery) || [],
            videoLink: e.videoLink ? getFullAddress(e.videoLink) : "",
            siteMap: convNull(e.siteMap) || [],
            completionYear: e.completionYear || "",
            projectFacilities:
                convNull(
                    e.projectFacilities.map((e) => {
                        return {
                            id: e.id
                        };
                    })
                ) || [],
            projectAmenities:
                convNull(
                    e.projectAmenities.map((e) => {
                        return {
                            id: e.id
                        };
                    })
                ) || [],
            hashtag: e.hashtag || "",

            saleForm: convNull(e.saleForm) || []
        };

        if (this.isAgency) {
            request.developerId = e.developerId || "";
        }

        return request;
    }

    fromResponseToEdit() {
        let e = cloneDeep(this.payload);
        return {
            developerId: e.developer.id || "",

            country: e.country || "",
            state: e.state || "",
            city: e.city || "",
            area: e.area || "",
            building: e.building || "",
            countryId: e.country.id || "",
            stateId: e.state.id || "",
            cityId: e.city.id || "",
            areaId: e.area.id || "",
            buildingId: e.building ? e.building.id : "",

            name: e.name || "",
            referenceCode: e.referenceCode || "",
            tenureType: convNull(e.tenureType) || [],
            furnishingType: e.furnishingType || "",
            propertyCategory: convNull(e.propertyCategory) || "",
            projectType: e.projectType || "",
            introduction: e.introduction || "",
            latitude: e.latitude || "",
            longitude: e.longitude || "",
            status: e.status || "",
            mainPhoto: convNull(e.mainPhoto) || [],
            gallery: convNull(e.gallery) || [],
            videoLink: convNull(e.videoLink) || "",
            siteMap: convNull(e.siteMap) || [],
            completionYear: e.completionYear || "",
            projectFacilities: convNull(e.projectFacilities) || [],
            projectAmenities: convNull(e.projectAmenities) || [],
            projectTags:
                e.hashtag === ""
                    ? []
                    : e.hashtag.split(",").map((tag) => ({ text: tag })),
            hashtag: e.hashtag || "",
            saleForm: convNull(e.saleForm) || []
        };
    }
}

export default ProjectModel;
