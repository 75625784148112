<template>
  <div class="create-project-form">
    <fd-form @submit="submitForm">
      <slot></slot>

      <!-- Update Button -->
      <div class="text-right p-2">
        <fd-button
          type="submit"
          class="main medium fixed round"
          :disabled="isLoading"
        >
          <i class="fas fa-plus mr-1"></i>
          Create
        </fd-button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import ProjectModel from "@/modules/Project/models/ProjectModel";

export default {
  components: {},
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "developer",
      validator: (val) => ["developer", "agency"].includes(val)
    },
    createProjectAPI: {
      type: Function
    },
    project: {
      type: Object,
      required: true
    },
    bankers: {
      type: Array,
      required: true
    },
    lawyers: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    setIsLoading(bool) {
      this.$store.commit("setIsLoading", bool);
      this.$emit("update:isLoading", bool);
    },

    async submitForm() {
      try {
        this.$store.commit("setIsLoading", true);
        let payload = {
          ...this.project,
          bankerIds: this.bankers,
          lawyerIds: this.lawyers
        };
        await this.createProjectAPI(
          new ProjectModel(payload, {
            isDeveloper: this.type === "developer",
            isAgency: this.type === "agency"
          }).toCreatePayload()
        );

        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Project is created successfully."
        });
        this.$router.push({ name: "ManageProjects" });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create new project."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
